import React from "react";
import Slider from "react-slick";
import "./styles/Gallery.css";

const images = [
  // Add the paths to your images here
  "/slide1.jpg",
  "/slide2.jpg",
  "/slide3.jpg",
];

const Gallery = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  
    return (
    <div className="gallery-section" id="gallery">
        <p className="gal-title">OUR WORK</p>
        <div className="gallery-wrapper">
            <Slider {...settings}>
            {images.map((img, index) => (
                <div className="image-container" key={index}>
                <img src={img} alt={`Gallery image ${index + 1}`} />
                </div>
            ))}
            </Slider>
        </div>
      </div>
    );
  };
  
  export default Gallery;