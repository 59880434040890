import React from 'react';
import './styles/Background.css';

const Background = ({ children }) => {
    return (
      <div>
        <header>
        </header>

        <section class="slanted-div">
            <div>
                {children}
            </div>
        </section>
      </div>
    );
  };

export default Background;