import NavBar from './components/NavBar';
import Background from './components/Background'; 
import Container from './components/Container'; 
import Header from './components/Header'
import MidRange from './components/MidRange';
import Gallery from './components/Gallery'
import About from './components/About'
import Contact from './components/Contact';
import "./components/styles/App.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  return (
    <div className="App">
      <Background>
        <Container>
          <NavBar></NavBar>
          <div className="shadow">
            <Header></Header>
            <MidRange></MidRange>
            <Gallery></Gallery>
            <About></About>
            <Contact></Contact>
          </div>
        </Container>
      </Background>
    </div>
  );
}

export default App;
