import React, { useRef } from 'react';
import './styles/NavBar.css';
import logo from './assets/logo.png'

const NavBar = () => {
  const scrollToContact = () => {
    const target = document.getElementById('contact');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToGallery = () => {
    const target = document.getElementById('gallery');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToAbout = () => {
    const target = document.getElementById('about');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

    return (
      <div className='nav-bar'>
        <img src={logo} alt="logo" className='logo'></img>
        <div className="nav-links">
          <a onClick={scrollToContact} className='link-box'><p>Contact</p></a>
          <a onClick={scrollToGallery} className='link'><p>Gallery</p></a>
          <a onClick={scrollToAbout} className='link'><p>About</p></a>
          <div className='clear'></div>
        </div>
      </div>
    );
  };

export default NavBar;