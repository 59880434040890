import React from 'react';
import './styles/Header.css';
import wood from "./assets/wood.jpg"

const Header = () => {
    return (
      <div className='header'>
        <img src={wood} alt="wood" className='header-img'></img>
        <p className='centered-text'>WE DO. GOOD DESIGN STUFF. TRUST.</p>
      </div>
    );
  };

export default Header;