import "./styles/MidRange.css"

const MidRange = () => {
    const scrollToContact = () => {
        const target = document.getElementById('contact');
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <div>
            <div className="left">
                <p className="info-title">CONSTRUCTION CONSTRUCTION and did I mention CONSTRUCTION</p>
                <div className="line"></div>
                <p className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className="right">
                <div className="box">
                    <p className="est-title">Free Estimates</p>
                    <p className="est-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed </p>
                    <div className="contact-button" onClick={scrollToContact}>
                        <p>CONTACT US</p>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default MidRange;