import "./styles/Contact.css"
import phone from "./assets/phone.png"

const Contact = () => {
    return (
        <div className="contact" id="contact">
            <p className="contact-title">CONTACT US</p>
            <div className="contact-card">
                <img src={phone}></img>
                <div className="right-card">
                    <p className="contact-name">Marilyn Lambert</p>
                    <p>(585) 625-8003</p>
                </div>
                <div className="clear"></div>
            </div>
            
        </div>
    )
}

export default Contact;